import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Typography, WrapperDiv } from '../components/common';
import { useStaticQuery, graphql } from 'gatsby';

const HomeText = {
  h1: `I’m a web developer made in Alentejo and living in Lisbon.`,
  h1Hidden: `I'm very passionate about code, tech and I love to deliver amazing user experiences.`,
  h2: `Hi, <br /> my name is Pedro.`,
  p: `I’m developing and planting Chaparros with the help of a keyboard and a mouse. I love to develop and take on challenges like I was jumping off an airplane without a parachute… okay… maybe with a parachute but.. you got the point.. I love a good challenge. I like to eat croquettes and I love to run as fast as Forest Gump.`,
};

const IndexPage = () => {
  const { allFile: items } = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "content" }
          extension: { eq: "md" }
          relativeDirectory: { regex: "/portfolio/" }
        }
        sort:{ fields: [dir], order: DESC }
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                title
                site
              }
              html
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Web Developer from Alentejo living in Lisbon" />
      <WrapperDiv>
        <Typography variant="h2" dangerouslySetInnerHTML={{ __html: HomeText.h2 }} />
        <Typography variant="h1" >{HomeText.h1} {HomeText.h1Hidden}</Typography>
        <Typography fontSize="16" variant="p">{HomeText.p}</Typography>
      </WrapperDiv>
    </Layout>
  )
}

export default IndexPage
